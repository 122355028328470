var search = {
	init: function () {

		var sp = document.querySelector('.search--collapsible-trigger');
		var searchbar = document.querySelector('.search--collapsible-target');
		var shclose = document.querySelector('.search--collapsible-target-close');
		var spMob = document.querySelector('.search--collapsible-trigger-mob');
		// var searchbarMob = document.querySelector('.search--collapsible-target-mob');
		// var shcloseMob = document.querySelector('.search--collapsible-target-close-mob');

		function changeClass() {
			searchbar.classList.add('search--collapsible-visible');
		}
		function closesearch() {
			searchbar.classList.remove('search--collapsible-visible');
		}
		function changeClassMob() {
			searchbar.classList.add('search--collapsible-visible');
		}

		sp.addEventListener('click', changeClass);
		shclose.addEventListener('click', closesearch);
		spMob.addEventListener('click', changeClassMob);
		// shcloseMob.addEventListener('click', closesearchMob);


		// Zet cursor op focus bij uitklappen
		// $('.search--collapsible-trigger').click(function(){
		// $(".search--collapsible-trigger").on("click", function(){
		// 	$('.search--collapsible-visible input').focus();
		// });



		document
			.getElementById("focusButtonVisibleIndication")
			.addEventListener("click", () => {
				document.getElementById("inputtextsearch").focus({ focusVisible: true });
			});
	}
}

// $(document).on("mouseup", function(e){
//     var container = $(".search--collapsible-target");
//     // if the target of the click isn't the container nor a descendant of the container
//     if (!container.is(e.target) && container.has(e.target).length === 0){
//         container.removeClass('search--collapsible-visible');
//     }
// });
