const scrollactions = {
	init: function () {
		
		var headerHeight = document.querySelector("header").clientHeight - 50;
		document.querySelector(".contentwrapper").style.paddingTop = headerHeight + "px"; 
		
		var header = document.querySelector("header")

		function scrollToTop() {
			if (document.body.scrollTop > 250 || document.documentElement.scrollTop > 250) {
				document.getElementById("backTop").className = "";
				header.classList.add("shrink")
			} else {
				document.getElementById("backTop").className = "masked";
				header.classList.remove("shrink");
			}
		}

		window.onscroll = function () { 
			scrollToTop() 
		};

	}
}

// WANNEER FILTER UITGEKLAPT OP MOBILE > VERBERG BACKTOP WANT DIE STAAT BOVEN ALLES
/* $('#collapseShow').on('shown.bs.collapse', function () {
	$('.backTop-container').css('right','-80px')
 });
 
 $('#collapseShow').on('hidden.bs.collapse', function () {
	$('.backTop-container').css('right','0')
 }); */