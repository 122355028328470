var hovercollapse = {
    init: function () {
        var collapse = $('.js-hovercollapse');
        var i;
        for (i = 0; i < collapse.length; i++) {
            collapse[i].addEventListener("mouseover", function (event) {
                var content = this.firstChild.nextElementSibling.nextElementSibling;
                if (content.style.maxHeight) {
                    content.style.maxHeight = null;
                }
                else {
                    content.style.maxHeight = content.scrollHeight + "px";
                    event.preventDefault();
                }
            });
            collapse[i].addEventListener("mouseout", function (event) {
                var content = this.firstChild.nextElementSibling.nextElementSibling;
                if (content.style.maxHeight) {
                    content.style.maxHeight = null;
                }
                else {
                    content.style.maxHeight = content.scrollHeight + "px";
                    event.preventDefault();
                }
            });

        }
    }
}