// var ready = (callback) => {
// 	if (document.readyState != "loading") callback();
// 	else document.addEventListener("DOMContentLoaded", callback);
// }
// ready(() => {
// 	console.log("document DOM is ready")
// });


window.onload = function () {
	// console.log("window onload - it works")
	scrollactions.init();
	// collapse.init();
	// hovercollapse.init();
	// dropdown.init();
	// mobileFilter.init();
	// search.init();
	// toast.init(); 
	// youtubesetsource.init(); // moet na fixedheader ingeladen worden
}
// window.onresize = function () {
	// console.log("window onresize - it works")
	// var screenwidth = $(window).outerWidth()
	// console.log('screenwidth = ' + screenwidth);
	// mobileFilter.init(); 
// };
 
